import {useState, useEffect} from 'react'
import { useSpring, animated, config} from 'react-spring'
import {H1, H2, Div, RecentTechDiv, P, Ul, Li, Link} from '../styles/About.styled'


const About = () => {

  return (
    <Div>
      <H1>About</H1>
      <P>I'm a Full Stack Software Engineer. I find great enjoyment in traversing technology and humanity. I'm captivated by the next possibility. I believe technology is a deeply human narrative. I desire to use my time in helping bring big ideas to life, with conscientious and thoughtful planning, in hopes to contribute to our beautiful story.</P>

      <RecentTechDiv>
        <H2>Tech that's been capturing me lately</H2>
        <Ul>
          <Li>DeepMind's work <Link target='_blank' href='https://www.deepmind.com/blog/alphafold-using-ai-for-scientific-discovery-2020'>Alpha Fold</Link></Li>
          <Li>Typescript</Li>
          <Li>Blockchain</Li>
        </Ul>
      </RecentTechDiv>
    </Div>
  )
}

export default About;