import React, { useState, useEffect } from 'react'

import Navbar from "./Navbar";
import MobileNav from "./MobileNav";
import {Logo, LogoDiv} from '../styles/Header.styled'
import { ReactComponent as LogoBall } from '../Assets/logo.svg';

const Header = ({selectHome, selectAbout, selectExperience, selectContact}) => {

  const [width, setWindowWidth] = useState(0);

  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);

    return () =>
      window.removeEventListener("resize", updateDimensions);

  }, [])


  const updateDimensions = () => {
    const width = window.innerWidth
    setWindowWidth(width)
  }

  return (
    <div>
      <LogoDiv>
        <LogoBall/>
      </LogoDiv>
      <div>
        {width < 700 ? <MobileNav 
          selectHome={selectHome}
          selectAbout={selectAbout}
          selectExperience={selectExperience}
          selectContact={selectContact}
        /> : 
        <Navbar
          selectHome={selectHome}
          selectAbout={selectAbout}
          selectExperience={selectExperience}
          selectContact={selectContact}
        />}
      </div>
    </div>
  )

}

export default Header;