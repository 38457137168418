import styled, {keyframes} from 'styled-components'


export const H1 = styled.h1 `
  color: white;
  text-align: center;
`
export const Div = styled.div `
  height: 100vh;
  margin-top: 10em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const ProjectsDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
  }
`

export const H2 =styled.h2 `
  color: white;
`

export const ProjectDiv = styled.div `
  width: 8em;
  height: 8em;
  background-color: #b07235;
  text-align: center;
  margin-left: 10vw;
`
export const DataMoonDiv = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const VideoKanbanDiv = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const VoiceoverVideoBookDiv = styled.div `
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Img = styled.img `
  width: 20em;
  border: solid 2px white;
  border-radius: 5px;
`
export const ScreenshotDiv = styled.div `
  width: 20em;
  border: solid 2px white;
  border-radius: 5px;
  margin-bottom: 1em;
  position: relative;
  display: inline-block;
  z-index: 0;

`
const fadeIn = keyframes`
  0% { opacity: 0;};
  100% { opacity: 1;};
`

export const ScreenshotOverlay = styled.div `
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 1;
  background: rgba(0, 0, 0, 0.7);
  animation: ${fadeIn} 1s linear;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ImgLink = styled.a `
  text-decoration: none;
`
export const Text = styled.p `
  color: white;
  font-size: 2rem;
  text-align: center;
`
export const P = styled.p `
  color: white;
  text-align: center;
`



