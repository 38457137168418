

import React from 'react'
import { P } from '../styles/Footer.styled'

const Footer = () => {
  return (
    <div>
      <P>Designed & Built by Lance Brackett</P>
    </div>
  )
}

export default Footer;