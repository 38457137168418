import styled from 'styled-components'


export const LogoDiv = styled.div `
  width: 48px;
  height: 48px;
  position: fixed;
  top: 1.6em;
  left: 2em;
  z-index: 2;
`

export const Logo = styled.img `
  width: 3em;
  position: fixed;
  top: 1.6em;
  left: 2em;
  z-index: 2;
`
