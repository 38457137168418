
import React, {useState} from 'react';
import { 
  Div,
  ProjectsDiv, 
  H1, 
  DataMoonDiv, 
  VideoKanbanDiv,
  VoiceoverVideoBookDiv,
  Img, 
  ImgLink,
  ScreenshotDiv,
  ScreenshotOverlay,
  Text,
  P
} from '../styles/Experience.styled'
import {ReactComponent as DataMoon} from "../Assets/DataMoon.svg"
import {ReactComponent as VideoKanban} from "../Assets/video-kanban-ss.svg"
import {ReactComponent as VoiceoverVideoBook} from "../Assets/voiceover-video-book.svg"

const Experience = () => {

  const [dataMoonOverlay, setDataMoonOverlay] = useState(false)
  const [videoKanbanOverlay, setVideoKanbanOverlay] = useState(false)
  const [voiceoverBookOverlay, setVoiceoverBookOverlay] = useState(false)

  return (
    <Div>
      <H1>Projects</H1>
      <ProjectsDiv>
        <VideoKanbanDiv>
          <ImgLink target="_blank" href="https://lucent-biscuit-65a545.netlify.app/">
            <ScreenshotDiv
              onMouseEnter={() => {setVideoKanbanOverlay(true)}}
              onMouseLeave={() => {setVideoKanbanOverlay(false)}}            
            >
              {videoKanbanOverlay ? <ScreenshotOverlay>
                <Text>Video Kanban</Text>
              </ScreenshotOverlay> : null}
              <VideoKanban/>
            </ScreenshotDiv>
          </ImgLink>
        <P>React | Typescript | Twilio</P>
        </VideoKanbanDiv>      
        <VoiceoverVideoBookDiv>
          <ImgLink target="_blank" href="https://papaya-lolly-d8ee9f.netlify.app/">
            <ScreenshotDiv
              onMouseEnter={() => {setVoiceoverBookOverlay(true)}}
              onMouseLeave={() => {setVoiceoverBookOverlay(false)}}                            
            >
              {voiceoverBookOverlay ? <ScreenshotOverlay>
                <Text>Voiceover Video Book</Text>
              </ScreenshotOverlay> : null}
              <VoiceoverVideoBook/>
            </ScreenshotDiv>
          </ImgLink>
          <P>React | ffmpeg.wasm</P>
        </VoiceoverVideoBookDiv>
        <DataMoonDiv>
          <ImgLink  target="_blank" href="https://sad-kirch-12188b.netlify.app">
            <ScreenshotDiv
              onMouseEnter={() => {setDataMoonOverlay(true)}}
              onMouseLeave={() => {setDataMoonOverlay(false)}}
            >
              {dataMoonOverlay ? <ScreenshotOverlay>
                <Text>Data Moon</Text>
              </ScreenshotOverlay> : null}
              <DataMoon />
            </ScreenshotDiv>
          </ImgLink>
          <P>Javascript | Jquery | html | css</P>
        </DataMoonDiv>
      </ProjectsDiv>
    </Div>
  )
}

export default Experience